<template>
  <div>
    <div class="z-0">
      <header>
        <ConnectWallet />
      </header>

      <div v-if="!getMenu">
        <div class="mx-auto sm:max-w-xl md:max-w-full xl:max-w-screen-2xl p-2">
          <div class="mt-24">
            <h3 class="text-3xl font-sans font-bold">#CraftMasters New</h3>
            <div
              class="grid gap-4 grid-cols-1 lg:grid-cols-3 md:grid-cols-2 mt-10"
            >
              <div>
                <img
                  class="w-full"
                  src="https://cdn.discordapp.com/attachments/997267666467291206/1085257291571413133/Raph0792_ultra_realistic_cute_black_and_white_dragon_with_huge__f942b8cb-becb-4669-8410-cb2f1d2eb8d4.png"
                />
                <div class="">
                  <h3 class="text-lg pt-5">It's Okay To Put Yourself First</h3>
                  <h3 class="text-md font-serif text-gray-700 pt-3">
                    During Mental Health Awareness Month, we're donating $5 from
                    every sale to To Write Love On Her Arms, a community
                    dedicated to preserving hope and finding help for people
                    struggling with depression, addiction, self-injury, and
                    suicide.
                  </h3>
                </div>
              </div>

              <div>
                <img
                  class="w-full"
                  src="https://cdn.discordapp.com/attachments/997267666467291206/1085257302954741800/Raph0792_ultra_realistic_cute_black_and_white_dragon_with_huge__39401ea7-6d10-4e9a-9381-515fcb2696c8.png"
                />
                <div class="">
                  <h3 class="text-lg pt-5">Be Breast Aware</h3>
                  <h3 class="text-md font-serif text-gray-700 pt-3">
                    A collection to raise breast cancer awareness. CraftMasters
                    will donate US$5 for each case sold from the collection to
                    Living Beyond Breast Cancer during October.
                  </h3>
                </div>
              </div>

              <div>
                <img
                  class="w-full"
                  src="https://cdn.discordapp.com/attachments/997267666467291206/1085257616709664880/Raph0792_ultra_realistic_cute_black_and_white_dragon_with_huge__12064737-b405-4856-87e3-60daf4e66bdb.png"
                />
                <div class="">
                  <h3 class="text-lg pt-5">Pawfect Love</h3>
                  <h3 class="text-md font-serif text-gray-700 pt-3">
                    Show your love for furry friends with this cuddly collection
                    that cares! During the month of August, CraftMasters will
                    donate $5 for every case sold to the IFAW.
                  </h3>
                </div>
              </div>
            </div>
            <hr class="mt-10 mb-10" />
          </div>

          <div class="mt-16">
            <div
              class="grid gap-4 grid-cols-1 lg:grid-cols-3 md:grid-cols-2 mt-10"
            >
              <div>
                <img
                  class="w-full filter grayscale"
                  src="https://cdn-stamplib.casetify.com/cms/image/b9b0129a0aca61d2b92b5ddeef41f3e8.jpg.webp"
                />
                <div class="">
                  <h3 class="text-lg pt-5">It's Okay To Put Yourself First</h3>
                  <h3 class="text-md font-serif text-gray-700 pt-3">
                    During Mental Health Awareness Month, we're donating $5 from
                    every sale to To Write Love On Her Arms, a community
                    dedicated to preserving hope and finding help for people
                    struggling with depression, addiction, self-injury, and
                    suicide.
                  </h3>
                </div>
              </div>

              <div>
                <img
                  class="w-full filter grayscale"
                  src="https://cdn-stamplib.casetify.com/cms/image/e2c50ed35659ba1e35d9076ae96b5ff2.jpg.webp"
                />
                <div class="">
                  <h3 class="text-lg pt-5">Be Breast Aware</h3>
                  <h3 class="text-md font-serif text-gray-700 pt-3">
                    A collection to raise breast cancer awareness. CraftMasters
                    will donate US$5 for each case sold from the collection to
                    Living Beyond Breast Cancer during October.
                  </h3>
                </div>
              </div>

              <div>
                <img
                  class="w-full filter grayscale"
                  src="https://cdn-stamplib.casetify.com/cms/image/fbd2847eab3cfceab2cf2d774ffe5778.jpg.webp"
                />
                <div class="">
                  <h3 class="text-lg pt-5">Pawfect Love</h3>
                  <h3 class="text-md font-serif text-gray-700 pt-3">
                    Show your love for furry friends with this cuddly collection
                    that cares! During the month of August, CraftMasters will
                    donate $5 for every case sold to the IFAW.
                  </h3>
                </div>
              </div>
            </div>
            <hr class="mt-10 mb-10" />
          </div>
        </div>

        <StickFooter class="mt-44" />
      </div>
    </div>
  </div>
</template>

<script>
import ConnectWallet from "@/components/ConnectWallet.vue";
import StickFooter from "@/components/Footer.vue";

import { mapGetters, mapActions } from "vuex";

import { POOL_ADDRESS, VERSION } from "../../config";

import { Carousel, Navigation, Pagination, Slide } from "vue3-carousel";

// import { products, main_product, products_2 } from "../../products";

export default {
  data() {
    return {
      version: VERSION,
      products: [],
      products_2: [],

      ref: this.$route.params.ref,
      searchPrompt: null,
      slideList: [
        {
          img: "https://cdn.discordapp.com/attachments/1080058777971212370/1126410892519682129/POD_Banner_for_web_02.png",
        },
        {
          img: "https://cdn.discordapp.com/attachments/1080058777971212370/1126407023160987708/POD_Banner_for_web_01.png",
        },

        {
          img: "https://cdn.discordapp.com/attachments/1080058777971212370/1126425212389445673/POD_Banner_for_web_03.png",
        },
      ],
    };
  },
  components: {
    ConnectWallet,
    StickFooter,
    Carousel,
    Navigation,
    Pagination,
    Slide,
  },
  computed: mapGetters(["getMenu"]),

  methods: {},
  created() {},
};
</script>
